@tailwind base;
@tailwind components;
@tailwind utilities;

/* Define your CSS variables (optional) */
:root {
  --primary-color: #1b4c52;     /* Dark green */
  --secondary-color: #08838b;   /* Deeper green */
  --accent-color: #ee2a07;      /* Orange for warnings/alerts */
  --background-color: #f0f4f0;  /* Light grayish green */
  --text-color: #605858;        /* Dark gray */
  --text-light: #ffffff;        /* White */
  --table-header: #4a5568;      /* Slate gray for table headers */
  --table-row-hover: #e2e8f0;   /* Light gray for table row hover */
  --button-primary: #38a169;    /* Green for primary buttons */
  --button-danger: #8a5a5a;     /* Red for delete buttons */
}

/* General styles */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  color: var(--text-color);
}

/* Prevent overflow issues */
html, body {
  overflow-x: hidden;
}
